/// <reference path="tnew">

// ---------------------------------- //
// ------- TNEW Shipping Page ------- //
// ---------------------------------- //

.tn-shipping-page {
    .tn-heading-sub-text {
        position: relative;
        margin-bottom: 10px;

        font {
            position: absolute;
            top: 0;
            left: -10px;

            &:before {
                content: '*';
            }

            small {
                display: none;
            }
        }
    }

    .tn-radio-group {
        margin-bottom: 40px;

        & > .radio:first-of-type {
            margin-top: 0;
        }
    }
}