/// <reference path="../tnew" />
// ------------- FONT SIZING MAPS ------------- //

$xl-font-sizes: (
    null: (
        2.5625rem,
        0.975
    ),
    medium: (
        2.25rem,
        0.975
    ),
    tablet: (
        1.75rem,
        1
    )
);

$lg-font-sizes: (
    null: (
        2.125rem,
        1
    ),
    medium: (
        1.75rem,
        1
    ),
    tablet: (
        1.25rem,
        1
    )
);

$md-lg-font-sizes: (
    null: (
        1.75rem,
        1.1
    ),
    medium: (
        1.5rem,
        1.1
    ),
    tablet: (
        1.125rem,
        1.2
    )
);

$md-font-sizes: (
    null: (
        1.25rem,
        1.1
    ),
    medium: (
        1.125rem,
        1.1
    ),
    tablet: (
        1rem,
        1.2
    )
);

$rg-font-sizes: (
    null: (
        1rem,
        1.5294
    ),
    tablet: (
        0.875rem,
        1.5294
    )
);

$sm-font-sizes: (
    null: (
        0.875rem,
        1.5294
    ),
    tablet: (
        0.75rem,
        1.5294
    )
);

// ------------- RESPONSIVE TYPOGRAPHY MIXIN ------------- //

@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
    @each $fs-breakpoint, $fs-font-size in $fs-map {
        @if $fs-breakpoint == null {
            @include make-font-size($fs-font-size);
        } @else {
            @if map-has-key($fs-breakpoints, $fs-breakpoint) {
                $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
            }

            @media screen and (max-width: $fs-breakpoint) {
                @include make-font-size($fs-font-size);
            }
        }
    }
}

@mixin make-font-size($fs-font-size) {
    @if type-of($fs-font-size) == 'list' {
        font-size: nth($fs-font-size, 1);

        @if (length($fs-font-size) > 1) {
            line-height: nth($fs-font-size, 2);
        }
    } @else {
        font-size: $fs-font-size;
    }
}
