/// <reference path="tnew">

// ------------------------------ //
// ------- TNEW Cart Page ------- //
// ------------------------------ //

.tn-cart-details-page,
.tn-payment-page,
.tn-receipt-page {
    .tn-heading-sub-text {
        padding-bottom: 15px;
    }

    .tn-cart-item {
        border-top: none;
        padding: 0;
    }

    .tn-cart-item-summary {
        @include media(tablet) {
            margin-bottom: 0;
        }
    }

    .tn-cart-item-summary__property--name,
    .tn-cart-item-summary__property--price-total {
        background-color: $tertiary;
        margin-bottom: 16px;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            height: 100%;
            width: 72px;
            background-color: $tertiary;
        }
    }

    .tn-cart-item-summary__property--name {
        font-family: $heading-font;
        line-height: 1;
        padding: 12px 0;
        font-size: 18px;
        text-transform: uppercase;

        @include media(tablet) {
            padding-right: 30px;
        }

        &:before {
            left: -72px;
        }

        &:after {
            @include media(tablet) {
                content: '';
                position: absolute;
                top: 0;
                height: 100%;
                width: 72px;
                background-color: $tertiary;
                right: -72px;
            }
        }

        p {
            margin: 8px 0 0 0;
            padding-bottom: 0;
            line-height: 1;
        }
    }

    .tn-cart-item-summary__property--price-total {
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 18px;
        line-height: 1;

        @include media(tablet) {
            background-color: transparent;
            padding-bottom: 0px;
            margin-bottom: 16px;
        }

        &:before {
            right: -72px;

            @include media(tablet) {
                display: none;
            }
        }

        @include media(tablet) {
            font-size: 17px;

            .sr-only {
                position: relative;
                width: auto;
                height: auto;
                clip: unset;
                margin: auto;
                font-weight: $font-weight-bold;
            }
        }
    }

    .tn-cart__btn-remove {
        border: none;
        background-color: transparent;
        z-index: 10;
        top: 5px;

        .tn-cart__btn-remove-icon {
            &:before,
            &:after {
                width: 5px;
                height: 23px;
            }
        }
    }

    .tn-cart-item-summary__property--date-time,
    .tn-cart-item-summary__property--location {
        font-size: 17px;
    }

    .tn-cart-item-details__list {
        padding: 0;
        color: $black;
        background-color: transparent;

        .tn-cart-item-details__list-item {
            color: $black;

            @include media(tablet) {
                padding-top: 10px;
            }

            ul {
                border-top: none;
                padding: 20px 0;

                @include media(tablet) {
                    padding: 4px 0 0 0;
                }
            }

            .tn-cart-item-detail__list-item {
                @include media(tablet) {
                    margin-bottom: 14px;
                }
            }

            &:not(:first-of-type) {
                &:nth-child(even) {
                    background-color: $primary-light;
                    position: relative;

                    &:before,
                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        height: 100%;
                        width: 72px;
                        background-color: $primary-light;
                    }

                    &:before {
                        left: -72px;
                    }

                    &:after {
                        right: -72px;
                    }
                }

                .tn-cart-item-detail__list-item--description:before,
                .tn-cart-item-detail__list-item--quantity:before,
                .tn-cart-item-detail__list-item--seat-location:before,
                .tn-cart-item-detail__list-item--pricetype:before,
                .tn-cart-item-detail__list-item--price:before {
                    display: none;

                    @include media(tablet) {
                        display: inline;
                    }
                }
            }
        }
    }

    .tn-cart-totals {
        .tn-cart-totals__line-item {
            @include media(tablet) {
                flex-wrap: nowrap;
            }
        }

        .tn-cart-totals__label,
        .tn-cart-totals__value {
            @include media(tablet) {
                width: auto;
                line-height: 1;
            }
        }

        .tn-cart-totals__label {
            color: $black;
            font-size: 17px;
            font-family: $body-font;
            font-weight: $font-weight-bold !important;
            text-transform: unset;
            display: flex;

            &:after {
                content: ':';
            }

            @include media(tablet) {
                margin-right: 8px;
            }
        }

        .tn-cart-totals__value {
            color: $black;
            font-size: 17px;
            font-family: $body-font;

            @include media(tablet) {
                min-width: 95px;
            }
        }

        .tn-cart-totals__line-item--total {
            border-top: 1px solid $secondary-medium;
            padding-top: 24px;
            margin-top: 24px;
        }
    }

    .tn-cart-buttons {
        justify-content: flex-start !important;

        .tn-cart-buttons__primary-action {
            @include media(tablet) {
                margin-bottom: 8px;
            }
        }

        .tn-cart-buttons__secondary-action {
            @extend .btn;
            @extend .btn-secondary;
            margin-right: 16px;
            text-align: center;
            text-decoration: none !important;

            &:hover {
                text-decoration: underline !important;
            }

            @include media(tablet) {
                margin-right: 0;
            }
        }
    }
}

.tn-cart-guest-page {
    label + .tn-required-field {
        position: absolute;
        top: 0;
        left: -16px;
    }
}
