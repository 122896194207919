/// <reference path="../tnew">

.tnew-footer {
    padding: 1.25rem 0 1.5rem;
    background: $black;
    color: $white;
    text-align: center;

    @include media-min(large) {
        padding: 0 0 1.5rem;
    }

    &-inner {
        max-width: 72.5rem;
        margin: 0 auto;

        @include media-min(tablet) {
            display: flex;
            justify-content: space-between;
        }
    }

    .footer-logo {
        display: none;
        padding-top: 0.5rem;

        @include media-min(tablet) {
            display: block;
        }

        svg {
            color: $white;
            display: block;
            width: 161px;
            height: 74px;
        }
    }

    .footer-content {
        &-contact {
            dl {
                margin: 0;
                padding: 0;

                @include media-min(tablet) {
                    text-align: left;
                    display: flex;
                }

                dt {
                    margin-bottom: 1rem;
                    font-family: $heading-font;
                    font-weight: 400;
                    font-size: 0.8125rem;
                    text-transform: uppercase;

                    @include media-min(tablet) {
                        margin: 0;
                        font-size: 0.75rem;
                    }
                }

                dd {
                    font-size: 1.0625rem;

                    @include media(tablet) {
                        line-height: 1.6;
                    }

                    @include media-min(tablet) {
                        padding-left: 3.5rem;
                    }

                    @include media-min(medium) {
                        padding-left: 3.75rem;
                    }
                }
            }

            a {
                color: $white;
                text-decoration: underline;
            }
        }

        .footer-tel {
            text-decoration: none;
        }
    }

    .social-icons {
        display: block;
        vertical-align: middle;
        list-style: none;
        margin: 0;
        padding: 0 0 5px;

        @include media-min(tablet) {
            padding: 0 0 15px;
        }

        @include media-min(large) {
            display: inline-block;
            padding: 0;

            &:after {
                content: '|';
                padding-left: 4px;
                font-size: 15px;
                display: inline-block;
                color: #fff;
                vertical-align: 5px;
                font-weight: 700;
            }
        }

        li {
            display: inline-block;
            padding: 0 0.75rem;

            @include media-min(large) {
                padding: 0 1rem 0 0;
            }

            a {
                color: #fff;
                font-family: $heading-font;
                font-weight: 400;
                font-size: 2.25rem;
                text-transform: uppercase;

                @include media-min(large) {
                    font-size: 1.375rem;
                }

                > span {
                    position: absolute;
                    left: -10000px;
                    overflow: hidden;
                }
            }
        }
    }

    nav.nav-footer {
        display: block;
        vertical-align: middle;
        padding: 0;
        font-size: 0.75rem;

        @include media-min(large) {
            display: inline-block;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                position: relative;
                display: block;
                padding: 6px 25px;

                @include media-min(tablet) {
                    display: inline-block;
                    padding: 0 25px 0 23px;

                    &:after {
                        content: '|';
                        font-size: 0.9375rem;
                        display: block;
                        position: absolute;
                        top: -0.125rem;
                        right: 0;
                        color: #fff;
                        font-weight: 700;
                    }
                }

                @include media(large) {
                    &:last-child:after {
                        display: none;
                    }
                }

                a {
                    color: #fff;
                    font-family: $heading-font;
                    font-weight: 400;
                    font-size: 0.8125rem;
                    text-transform: uppercase;

                    @include media-min(tablet) {
                        font-size: 0.75rem;
                    }
                }

                &.menu-join-our-newsletter {
                    a:before {
                        content: url(/wp-content/themes/skirball/dist/images/svg-sprite/icon-newsletter.svg);
                        display: none;
                        margin: 0 12px 0 0;
                        vertical-align: middle;

                        @include media-min(medium) {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

    .footer-nyu-logo {
        display: block;
        margin: 0 0 0.5rem;

        @include media-min(tablet) {
            margin: 0;
        }

        @include media-min(medium) {
            display: inline-block;
            vertical-align: middle;
        }

        @include media-min(large) {
            margin: 0 0 0 20px;
        }

        svg {
            width: 87px;
            height: 75px;

            @include media-min(tablet) {
                width: 71px;
                height: 89px;
            }
        }
    }
}
