/// <reference path="../tnew">

$tnew-breakpoint: 767px;
$nav-height: 60px;

*:focus {
    outline: 3px solid #3dbbdb !important;
    outline-offset: 3px !important;
}

.tnew-site-wrapper,
.modal {
    .alert-warning,
    .bg-danger {
        background-color: transparent !important;
        border-color: $red;
        border-radius: 0;
        color: $red;
    }
}

.tnew-wrap {
    position: relative;
    flex: 1 0 auto;
    padding-bottom: $gap-lg;
    color: $primary-font-color;

    div:focus,
    fieldset:focus,
    p:focus {
        box-shadow: none;
        outline: 0;
    }

    .tn-waiting {
        float: none;
    }

    a:not(.btn) {
        @extend %tnew-links;
    }

    > .container-fluid {
        @include responsive-property('padding-left', $site-padding-map);
        @include responsive-property('padding-right', $site-padding-map);

        main {
            padding-top: 0;
        }
    }
}

// Header bar
#tn-apply-promo {
    display: flex;
}

.tn-subnav-component {
    .tn-promo-box {
        margin-right: 0;

        input {
            @extend %form-element;
            width: 10rem;

            @include media(small) {
                width: 5rem;
            }
        }
    }
}

.tn-logged-in {
    @include media($tnew-breakpoint) {
        font-size: 75%;
    }

    &-email {
        word-break: break-all;
    }
}

@media print {
    .tnew-header,
    .tn-subnav-component,
    .tn-promo-box,
    .tn-admin-preview-banner {
        display: none !important;
    }

    h1 {
        font-size: 1.75rem !important;
        margin: 0 0 0.625rem 0 !important;
    }

    h2 {
        font-size: 1.25rem !important;
        margin-top: 0 !important;
    }

    .tn-order-address-information-component,
    .tn-order-information-line.tn-additional-information {
        margin-bottom: 1.875rem !important;
    }

    .tn-order-address-information-component {
        margin-top: 0.5rem !important;
    }

    .tnew-footer .footer-content-main {
        display: none !important;
    }
}

%tnew-links {
    color: $black;
    font-weight: $font-weight-bold;
    text-decoration: underline;

    &:hover {
        color: $black;
    }
}

%visually-hidden {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute !important;
}
