/// <reference path="tnew">

// --------------------------------- //
// ------- TNEW Payment Page ------- //
// --------------------------------- //

.tn-payment-page {
    .tn-patron-billing-information > div {
        @include media(medium) {
            margin-bottom: 40px;
        }
    }
    
    #tn-add-donation-form {
        margin: 20px 0 75px !important;
    }

    .input-group-addon {
        border-color: $secondary-medium;
        border-radius: 0;
    }

    .tn-gift-certificates-redemption-component {
        margin-bottom: 40px;
    }

    #tn-payment-apply-gift-certificate-form {
        .input-group {
            @include media(tablet) {
                display: block;
            }
        }

        button {
            @include media(medium) {
                margin-top: 16px;
            }
        }
    }

    .tn-payment-component {
        .tn-order-notes-component {
            margin-bottom: 40px;

            .form-group {
                margin-bottom: 0 !important;
            }
        }
    }

    .tn-order-note {
        min-height: 250px;

        @include media(tablet) {
            width: 100% !important;
            max-width: 100% !important;
        }
    }

    [data-control-group-for="Checkout_FormKey"] {
        @extend %visually-hidden;
    }

    .tn-payment-submit-component {
        margin-top: 40px;
    }
}