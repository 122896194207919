/// <reference path="../tnew">

.skip-to-content {
    background: white;
    position: absolute;
    left: -20%;
    top: 30px;
    padding: 10px 30px;
    z-index: 10000;
    opacity: 0;
    transition: all 0.3s;
    font-weight: 700;
}

.skip-to-content:focus {
    opacity: 1;
    left: 20%;
}

header.tnew-header {
    position: static;
    background: $black;
    color: $white;
    height: $header-height-mobile;
    text-align: center;
    box-shadow: 0 0 8px 5px rgba(0, 0, 0, 0.2);

    @include media-min(tablet) {
        height: $header-height;
        margin-bottom: 60px;
    }

    .logo {
        display: inline-block;
        float: none;
        margin: -4px auto 0;
        width: auto;
        -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
        filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));

        @include media-min(tablet) {
            display: block;
            margin: 11px 0 0 8px;
        }

        .icon-svg {
            color: #fff;
            margin: 0;

            &-logo-mobile {
                display: block;
                width: 161px;
                height: 74px;
            }

            &-logo-desktop {
                display: none;
                width: 189px;
                height: auto;
            }

            @include media-min(tablet) {
                &-logo-mobile {
                    display: none;
                }

                &-logo-desktop {
                    display: block;
                }
            }
        }
    }
}

// .tn-admin-preview-banner {
//     position: fixed;
//     z-index: 1000000;
//     padding: 0.5rem;

//     h2 {
//         padding: 0 0.5rem 0 0;
//         font-size: 1rem;
//         font-weight: normal;
//     }
// }

.tnew-site-wrapper {
    .tn-subnav-component {
        // @include media-min(tablet) {
        //     position: fixed;
        //     @include responsive-property('right', $site-padding-map);
        //     top: 0;
        //     z-index: 100000;
        // }

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: auto;
        background: $black;
        margin: 0 -1rem;
        padding: 0;

        @include media-min(tablet) {
            width: calc(100% - 190px);
            margin: 0;
            margin-left: 190px;
            margin-top: -130px;
            margin-bottom: 130px;
            background: none;
            justify-content: flex-end;
        }

        a {
            color: $white !important;
            font-size: 1.375rem;
            font-family: $heading-font;
            font-weight: 700;
            letter-spacing: 0.125rem;
            text-transform: uppercase;
            text-decoration: none !important;

            > span,
            .tn-text {
                display: inline-block !important;
                margin: 0 !important;
                font-size: 1em !important;
            }

            .tn-icon,
            .tn-logged-in-text,
            &:before {
                display: none !important;
            }

            &:hover {
                .tn-text {
                    text-decoration: underline;
                }
            }

            &.tn-account-info-link {
                @include media(tablet) {
                    display: block;
                }
            }

            .tn-logged-in-email {
                color: $primary-medium;
            }
        }

        .tn-login-link {
            margin: 0 !important;

            a {
                @include responsive-property('margin-left', $header-nav-gap-map);
                @include responsive-property('margin-right', $header-nav-gap-map);
                margin-bottom: 1rem;

                @include media-min(tablet) {
                    margin-right: 0;
                    margin-bottom: 0;
                }
            }

            .tn-link,
            .tn-account-info-link {
                display: inline-block;
            }

            &.tn-logged-in {
                .tn-logout-link {
                    display: inline-block;

                    @include media(small) {
                        display: none;
                    }
                }
            }
        }

        .tn-cart-link {
            flex: 0 0 auto;
            margin: 0;
            margin-bottom: 1rem;
            font-size: 1.375rem;
            font-family: $heading-font;
            font-weight: 700;
            letter-spacing: 0.125rem;
            text-transform: uppercase;
            color: $white;
            @include responsive-property('margin-left', $header-nav-gap-map);

            @include media-min(tablet) {
                margin-bottom: 0;
            }

            .tn-timer {
                font-size: 1em;
                @include responsive-property('margin-left', $header-nav-gap-map);
            }

            .tn-count-down-timer {
                color: $primary-medium;
            }
        }

        %promo-box,
        .tn-promo-box {
            display: flex;
            width: 100%;
            margin: 0;
            padding-top: 1.25rem;
            justify-content: flex-end;
            background: $white;

            @include media(tablet) {
                @include responsive-property('padding-left', $site-padding-map);
                @include responsive-property('padding-right', $site-padding-map);
            }

            @include media-min(tablet) {
                margin: 0 0 -150px;
                background: none;
            }

            form {
                flex: 0 0 auto;
                width: 100%;

                @include media-min(small) {
                    width: auto;
                }
            }

            .tn-subnav-promo-button,
            .tn-link.btn-default {
                background: $black !important;
                color: $white !important;
                font-weight: bold;
                padding-left: 1.25rem;
                padding-right: 1.25rem;
                text-decoration: none;

                &:hover,
                &:focus {
                    color: $white !important;
                    text-decoration: underline;
                }
            }
        }

        /*
        * Form is nested inside Promo box wrapper by default when no promo is applied
        * When promo is applied however, form wraps the promo box for some random reason
        */
        #tn-remove-promo {
            @extend %promo-box;

            .tn-promo-applied {
                flex: 0 0 auto;
                width: auto;
                margin: 0;
                padding: 0;

                &:before {
                    display: none;
                    content: none;
                }

                .tn-text {
                    font-size: 1rem;
                    font-weight: $font-weight-bold;
                }

                .tn-link.btn-default {
                    min-width: auto;
                    margin-left: 1rem;

                    .tn-icon {
                        font-size: 1em;
                    }
                }
            }
        }
    }
}
