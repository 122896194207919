/// <reference path="tnew">

// ------------------------------ //
// ------- TNEW Events Page ------- //
// ------------------------------ //

.tn-events-listing-page {
    .tn-btn-datepicker__btn {
        padding: 0 10px !important;

        &-period-prev-next {
            min-width: 0;
            padding: 0 1rem !important;
        }
    }

    .tn-btn-datepicker__icon-container .glyphicon {
        color: $blue;
    }

    .tn-events-list-view__datepicker-divider-label {
        color: $black;
    }

    .tn-event-listing-view__results-heading {
        display: none;
    }

    .tn-event-listing-mode-tab-nav__list-item {
        color: $primary-font-color;
        text-decoration: underline;

        &.active {
            text-decoration: none;
        }
    }

    .tn-events-calendar__table {
        thead th {
            color: $primary-font-color;
            background: $tertiary;
            font-weight: $font-weight-bold;
            font-family: $heading-font;
            @include font-size($lg-font-sizes);
        }
    }

    .tn-events-calendar__event {
        min-width: 0;
        padding: 35px 15px 15px !important;
        font-weight: normal;
        font-size: 0.75rem !important;

        &-name {
            line-height: 1.4;
        }

        &-status {
            background-color: rgba($white, 0.175);
        }

        .tn-product-type-name {
            padding: 0;
            margin-bottom: 0.5rem;
        }

        @include media-min(tablet) {
            padding: 30px 10px 7px 5px !important;
            font-size: 0.875rem !important;
        }
    }
}

// Outside of page container to affect all prod-list examples

.tn-prod-list-item__property--heading {
    @include font-size($lg-font-sizes);
}

.tn-prod-list-item__perf-anchor {
    &:focus {
        z-index: 1;
    }
}

.tn-prod-list-item .glyphicon {
    min-width: 0;
    color: $secondary-dark;
    background: $white;
}

.tn-prod-list-item__perf-property--datetime {
    color: $black !important;
}

.tn-product-type-name {
    display: inline-block;
    padding: 0.5rem;
    font-weight: $font-weight-bold;
    color: $white;
    background: $black;
    border-radius: 0;
    border: 0;
}
