/// <reference path="../tnew">

%btn,
.btn,
input[type='button'] {
    display: inline-block;
    font-size: 16px !important;
    font-weight: 700;
    padding: 12px 18px !important;
    width: auto;
    min-width: 215px;
    border-radius: 0;

    @include media(small) {
        width: 100%;
    }

    &:not([disabled]):hover,
    &:not([disabled]):focus {
        text-decoration: underline !important;
    }

    &[disabled='1']:hover,
    &[disabled='1']:focus {
        text-decoration: none !important;
    }

    &[disabled],
    &[disabled='1'] {
        cursor: not-allowed;
        opacity: 0.5 !important;
    }
}

.btn-primary,
.btn-info,
input[type='button'] {
    border: 1px solid $white !important;
    background-color: $black !important;
    color: $white !important;
}

%btn-primary {
    @extend %btn;
    @extend .btn-primary;
}

.btn-secondary,
.btn-default {
    border: 1px solid $black !important;
    background-color: $white !important;
    color: $black !important;
}

.btn-tertiary {
    border: 1px solid $white !important;
    background-color: $primary-medium !important;
    color: $black !important;
}

.tnew-site-wrapper {
    .tn-datepicker {
        .btn {
            min-width: 0;
            padding: 0 !important;
        }
    }
}
