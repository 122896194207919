/// <reference path="tnew">

// -------------------------------------- //
// ------- TNEW Confirmation Page ------- //
// -------------------------------------- //


.tn-receipt-page {
    .tn-receipt-component .col-lg-push-9 {
        text-align: right;
    }

    .tn-receipt-print {
        @include media(medium) {
            margin-bottom: 44px;
        }
    }

    .tn-patron-billing-information > div {
        @include media(medium) {
            margin-bottom: 40px;
        }
    }

    .tn-order-address-information-component {
        margin: 40px 0 62px 0;
    }

    .tn-order-address-information-component + .row .tn-additional-information {
        margin-bottom: 68px;
    }
    
    .tn-cart-buttons__primary-action {
        @extend .btn-primary;
    }
}