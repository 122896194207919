/// <reference path="tnew">

// ------------------------------- //
// ------- TNEW CTA Blocks ------- //
// ------------------------------- //

.cta-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;

    & > .cta {
        width: calc(50% - 16px);

        @include media($medium-media-query-size) {
            width: 100%;
            margin-bottom: 0px !important;
        }

        &:nth-child(odd) {
            margin-right: 16px;

            @include media($medium-media-query-size) {
                margin-right: 0;
            }
        }

        &:nth-child(even) {
            margin-left: 16px;

            @include media($medium-media-query-size) {
                margin-left: 0;
            }
        }

        & + .cta {
            @include media($medium-media-query-size) {
                margin-top: 18px;
            }
        }
    }

    & > :last-child.cta {
        margin-bottom: 32px !important;
    }
}

%cta,
.cta {
    padding: 24px;
    margin-bottom: 32px !important;

    &-yellow {
        background-color: $primary-medium;
    }

    &-blue {
        background-color: $tertiary;
    }

    &-heading {
        font-size: 18px;
        padding: 0 0 10px 0;
        line-height: 1;
    }

    & > :last-child.cta-btns {
        margin-bottom: -10px !important;
    }

    &-btns {
        display: flex;
        flex-wrap: wrap;

        a {
            border-color: $black !important;
            margin-bottom: 10px !important;

            &:first-of-type {
                margin-right: 16px !important;

                @include media($small-media-query-size) {
                    margin-right: 0 !important;
                }
            }
        }
    }

    input[type='button'] {
        border-color: $black !important;
        margin-bottom: 10px !important;

        &:first-of-type {
            margin-right: 16px !important;

            @include media($small-media-query-size) {
                margin-right: 0 !important;
            }
        }
    }
}

.tn-cart-payment-validation-message {
    @extend %cta;

    background-color: $blue;
}

// Example markup
//
// <div class="cta-container">
//     <div class="cta cta-yellow">
//         <h2 class="cta-heading">Are You a Member?</h2>
//         <p>Log in for member benefits or join today for as little as $65.</p>
//         <div class="cta-btns">
//             <a href="#" class="btn btn-primary">Log in</a>
//             <a href="#" class="btn btn-primary">Become a Member</a>
//         </div>
//     </div>
//     <div class="cta cta-blue">
//         <h2 class="cta-heading">NYU Community</h2>
//         <p>NYU students, faculty, administrators, and alumni get you tickets here.</p>
//         <div class="cta-btns">
//             <a href="#" class="btn btn-primary">Get Tickets</a>
//         </div>
//     </div>
// </div>
