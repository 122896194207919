/// <reference path="tnew">

// -------------------------- //
// ------- TNEW Modal ------- //
// -------------------------- //

.modal {
    padding-right: 0 !important;

    @include media(small) {
        margin-top: 10px;
    }

    a:not(.btn) {
        @extend %tnew-links;
    }

    .tn-modal-heading-info {
        border-radius: 0;
        background-color: transparent;
    }

    &-header {
        .close {
            background: $black;
            border: 0;

            &:before,
            &:after {
                background-color: $white;
            }
        }
    }

    &-content {
        margin-top: 30px 0 !important;
        border-radius: 0;
    }

    .form-group,
    .form-control {
        max-width: none !important;
    }

    &-footer .btn {
        @include media(small) {
            min-width: unset;
        }
    }
}
