/// <reference path="tnew">

// ------------------------------ //
// ------- TNEW SYOS Page ------- //
// ------------------------------ //

.tn-events-detail-page {
    border-bottom: 1px solid $secondary-medium;

    @include media($tablet-media-query-size) {
        padding-bottom: 54px !important;
    }
}

.tn-prod-season-header {
    border-top: 1px solid $secondary-medium;
    border-bottom: none !important;
    padding-top: 36px;

    &__title {
        @extend %heading;
        font-size: 28px !important;
    }
}

.tn-event-detail__additional-events-container {
    max-width: 21.875rem;
}

.tn-event-detail__main-container {
    position: relative;

    @include media(tablet) {
        margin-bottom: 0;
    }
}

.tn-additional-events--desktop-format-select-list {
    max-width: 404px;
    margin-bottom: 0 !important;

    @include media($tablet-media-query-size) {
        max-width: 100%;
    }

    .tn-additional-events__controls-container {
        border-bottom: none;

        .input-group {
            width: 100%;
        }
    }

    .tn-additional-events__date-select {
        width: calc(100% - 52px) !important;
    }

    .input-group {
        @include media($tablet-media-query-size) {
            width: 100%;
        }
    }

    .input-group-btn {
        display: block;
        margin-top: -1px;

        button {
            height: 52px;
            width: 52px;
        }
    }
}

.tn-additional-events__continue-shopping-link {
    display: inline-block !important;
    margin-bottom: 6px;

    & + .tn-ticketing-mode-change__content {
        display: block;
        margin-top: 16px;
    }
}

.tn-additional-events__btn-submit {
    min-width: unset !important;
}

.tn-event-detail__performance-details-container p:empty {
    display: none;
}

.tn-ticketing-mode-change__content {
    border: none !important;
    background-color: transparent !important;
    padding: 7px 0 !important;

    .tn-ticketing-mode-change__anchor {
        font-style: normal;
        font-size: 16px;
    }
}

.tn-ticketing-mode-change-position {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;

    @include media(tablet) {
        top: -34px;
    }
}

.tn-syos__layout-container {
    border: none !important;
    overflow: visible !important;
}

.tn-syos__screen-view-components {
    overflow: hidden !important;
}

.tn-syos .tn-syos-screen-container {
    &__button-list-heading {
        color: $black;
    }

    &__button-list-container {
        background-color: $white;

        .tn-syos-screen-button {
            @extend %btn-primary;
            width: 100%;

            &__section-heading {
                border-color: $white;
            }
        }
    }

    &__map-container img {
        margin: 0 auto;
        max-width: 715px !important;
        width: 100%;
    }
}

.tn-syos-price-type-selector__content-container {
    background-color: $primary-light;
}

.tn-syos-cart__header,
.tn-syos-seat-info__header {
    background-color: transparent !important;
}

.tn-modal__btn-close {
    border: none !important;
    background-color: transparent;
}

.tn-syos-cart-seat__btn-remove {
    @extend .btn-secondary;
}

.tn-syos-cart.tn-syos-mobile-popover {
    border: none;
}

.tn-syos-cart__content-container {
    background-color: $primary-light !important;

    @include media(small) {
        background-color: $white !important;
    }

    .tn-syos-cart__summary {
        @include media(small) {
            background-color: $primary-light;
        }
    }

    .tn-syos__btn-add-to-cart {
        position: relative !important;
        width: auto !important;
        top: unset !important;
    }
}

.tn-syos-mobile-cart-controls {
    height: 118px !important;

    @include media($tablet-media-query-size) {
        height: 145px !important;
    }
}

.tn-syos-mobile-cart-controls__summary {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    border: none !important;
    background-color: transparent !important;
    text-align: right;
    max-width: calc(100% - 220px);

    @include media($tablet-media-query-size) {
        position: absolute !important;
        top: 12px;
        height: 20px !important;
        padding: 0 !important;
        max-width: 100%;
    }

    & > div {
        opacity: 1;
        font-size: 18px;

        & + div {
            margin-left: 8px;

            &:before {
                content: '(';
            }

            &:after {
                content: ')';
            }
        }
    }
}

.tn-syos-mobile-cart-controls__btn-view-cart,
.tn-syos__btn-add-to-cart {
    @extend .btn;
    text-transform: none !important;
    font-style: normal;
}

.tn-syos-mobile-cart-controls__btn-view-cart {
    @extend .btn-tertiary;
    position: absolute;
    top: 0;
    right: 0;
    height: 49px;

    @include media($tablet-media-query-size) {
        top: 45px;
        width: 100% !important;
    }

    .glyphicon {
        display: none;
    }

    .tn-syos-mobile-cart-controls__btn-view-cart-label {
        font-size: 16px !important;
        font-weight: 700;
        text-transform: none;
    }
}

.tn-syos__btn-add-to-cart {
    @extend .btn-primary;
    position: absolute;
    top: 68px;
    right: 0;

    @include media($tablet-media-query-size) {
        top: 112px;
        width: 100%;
    }
}

.tn-syos--viewing-seats {
    @include media($tablet-media-query-size) {
        // .tn-additional-events--desktop-format-select-list {
        //     display: none;
        // }

        .tn-syos-seat-map {
            transform-origin: 0 -160px !important;
        }

        .tn-syos-legend__btn-show-legend-mobile {
            bottom: 275px !important;
        }

        .tn-syos-zoom-control {
            bottom: 200px !important;
        }

        .tn-syos-mobile-cart-controls {
            left: 20px !important;
            right: 20px !important;
            bottom: 35px !important;
        }
    }
}

.tn-ticket-selector__pricetype-select {
    -webkit-appearance: menulist !important;
}
