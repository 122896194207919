/// <reference path="_tnew-amp">

// ---------------------------------- //
// ------- TNEW Register Page ------- //
// ---------------------------------- //

.tn-patron-component,
.tn-address-component,
.tn-interests {
    h2 {
        margin-top: 16px !important;
    }

    .form-group:first-of-type {
        margin-top: 0; 
    }
}