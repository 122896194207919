/// <reference path="tnew">

// ------------------------------- //
// ------- TNEW Login Page ------- //
// ------------------------------- //

.tn-login-component .form-group:first-of-type {
    margin-top: 0; 
}

.tn-account-login-forgot-login-link {
    color: $black !important;
    font-weight: $font-weight-bold;
    text-decoration: underline;

    &:hover {
        color: $black !important;
    }
}

.tn-login-facebook-button {
    @extend .btn-secondary;
}
