/// <reference path="tnew">

// ----------------------------------------- //
// ------- TNEW Account Update Page ------- //
// ----------------------------------------- //

.tn-account-update-page,
.tn-account-interests-page {
    & > .col-xs-12 {
        padding: 0;

        .tn-account-update,
        .tn-account-interests {
            @include media(tablet) {
                border: none;
                padding-left: 0;
                padding-right: 0;

                &:hover {
                    border: none;
                }
            }
        }
    }

    .nav-tabs {
        @include media(tablet) {
            border-bottom: 1px solid $secondary-medium;

            & > li {
                margin-bottom: 0;
            }
        }
    }

    #tn-update-button {
        margin: 1em 16px 0 0;
    }
}

.tn-account-interests-page {
    .btn-primary {
        margin: 1em 16px 0 0;
    }
}