/// <reference path="../tnew">

html,
body {
    font-size: 16px; // IE11 fix for TNEW
    font-size: unset;
}

body {
    font-family: $body-font;
    line-height: 1.4;
    color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6,
%heading {
    font-family: $heading-font;
    font-weight: 500 !important;
    line-height: 1.1;
    color: inherit;
    text-transform: uppercase;
}

h1 {
    @include font-size($xl-font-sizes);
}

h2 {
    @include font-size($lg-font-sizes);
}

h3 {
    @include font-size($md-lg-font-sizes);
}

h4 {
    @include font-size($md-font-sizes);
}

h5 {
    @include font-size($rg-font-sizes);
}

h6 {
    @include font-size($sm-font-sizes);
}

p {
    line-height: 1.375rem;
    margin: 0 0 0.625rem;
    padding-bottom: 0.625rem;

    @include media-min(tablet) {
        line-height: 1.625rem;
        padding-bottom: 0.9375rem;
    }
}

.icon-svg {
    color: currentColor;
}

[color='red'] {
    color: $red;
}
