/// <reference path="../tnew">

/// Input types that are visually compatible with text style inputs
/// @name form styling
$input-types: (
    'text',
    'number',
    'password',
    'tel',
    'email',
    'search',
    'url',
    'date',
    'month',
    'week',
    'time',
    'datetime',
    'datetime-local'
);

$field-vertical-spacing: $gap-sm;
$field-vertical-padding: $space;

%form-element {
    /*minimum size for form element font-size to prevent iOS resizing is 16px*/
    font-size: 16px !important;
    font-family: $body-font;
    line-height: 1.75;
    width: 100%;
    max-width: $max-input-width;
    background: $white;
    border: 1px solid $secondary-medium;
    border-radius: $border-radius;
    height: 50px !important;
    padding: 0 1.25rem;
    // fix for to stop iOS' need for special UI styling
    -webkit-appearance: none;

    &[disabled] {
        background: $white;
        cursor: not-allowed;
    }
}

.tnew-wrap,
.modal {
    // inherits custom radio and checkbox styles from main stylesheet
    .checkbox,
    .radio {
        margin-left: 19px;

        label {
            padding-left: 0;
            line-height: 1;
        }
    }

    .checkbox {
        .tn-required-field:after {
            left: -30px;
        }
    }

    input[type="radio"],
    input[type="checkbox"] {
        top: 0;
    }

    .tn-required-field,
    .has-error .checkbox,
    .has-error .checkbox-inline,
    .has-error.checkbox-inline label,
    .has-error.checkbox label,
    .has-error .control-label,
    .has-error .help-block,
    .has-error .radio,
    .has-error .radio-inline,
    .has-error.radio-inline label,
    .has-error.radio label {
        color: $red;
    }

    .has-error .form-control:focus {
        border-color: $secondary-medium;
        box-shadow: none;
    }

    .alert-danger {
        background: rgba($red, 0.2);
        color: $red;
    }

    .bg-danger {
        background-color: rgba($red, 0.2);
        border-radius: 0;
    }

    .tn-heading-error {
        @include font-size($rg-font-sizes);
    }

    .form-control {
        @extend %form-element;
        height: auto;

        @include media(small) {
            max-width: none !important;
        }
    }

    .form-group {
        margin: 16px 0 !important;
        max-width: 50rem;
        position: relative;

        @include media(small) {
            max-width: none;
        }

        &.has-error {
            label,
            .help-block {
                color: $red;
            }
        }

        .glyphicon {
            display: none;
        }
    }

    .control-label {
        margin-bottom: $space;
        width: 75%;
        order: 1;

        &:before {
            display: none;
        }
        
        & + .tn-required-field {
            position: absolute;
            top: 0;
            left: -16px;
        }
    }

    .tn-required-field {
        color: transparent;
        order: 0;
        margin-right: $gap;

        &:after {
            content: '*';
            position: absolute;
            left: 0.5rem;
            color: $red;
        }
    }
}
