/// <reference path="../tnew">

// ------------- MEDIA QUERIES ------------- //
//@media (max-width:$x-large-media-query-size) 1600px
$x-large-media-query-size: 100em !default;

//@media (max-width:$large-media-query-size) 1280px
$large-media-query-size: 80em !default;

//@media (max-width:$medium-media-query-size) 992px
$medium-media-query-size: 62em !default;

//@media (max-width:$tablet-media-query-size) 767px
$tablet-media-query-size: 47.9375em !default;

//@media (max-width:$small-media-query-size) 600px
$small-media-query-size: 37.5em !default;

//@media (max-width:$mobile-media-query-size) 340px
$mobile-media-query-size: 21.25em !default;

// ------------- BREAKPOINTS MAP ------------- //
$breakpoints: (
    x-large: $x-large-media-query-size,
    large: $large-media-query-size,
    medium: $medium-media-query-size,
    tablet: $tablet-media-query-size,
    small: $small-media-query-size,
    mobile: $mobile-media-query-size
) !default;

// ------------- COLORS ------------- //
// base colors
$white: #ffffff !default;
$black: #000000 !default;
$red: #ff0000 !default;
$blue: #00a9e0 !default;
$green: #00b2a9 !default;

// branding
$primary-dark: #005c54 !default;
$primary-medium: #fadc41 !default;
$primary-light: #f5f5f5 !default;
$secondary-dark: #00100e !default;
$secondary-medium: #737373 !default;
$secondary-light: $primary-light !default;
$tertiary: $green !default;

$primary-font-color: $secondary-dark !default;
$secondary-font-color: $secondary-medium !default;

// ------------- FONTS ------------- //
$body-font: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif !default;
$heading-font: Druk, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif !default;
$accent-font: Druk, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif !default;

// ------------- FONT WEIGHTS ------------- //
$font-weight-normal: 400 !default;
$font-weight-bold: 800 !default;

// ------------- WHITESPACE/WIDTH ------------- //
$pixel-em: calculateEm(1px);
$gap: 2.5rem !default;
$gap-sm: 1rem !default;
$gap-lg: 4rem !default;
$space: 0.5rem !default;
$max-width: 90rem !default;
$max-input-width: 45rem !default;
$border-radius: 0 !default;

// ------------- MISCELLANEOUS --------------- //
$transition: 0.2s !default;
$shadow: 0 0.0625rem 0.125rem rgba($black, 0.1) !default;
$header-height: 100px !default;
$header-height-mobile: 75px !default;

// ------------- PADDING/MARGIN MAPS --------- //
// For setting site edge padding
$site-padding-map: (
    null: 72px,
    medium: 1rem
) !default;

$header-nav-gap-map: (
    null: 2.625rem,
    tablet: 1rem
) !default;
